import React from "react";
import { useEffect } from "react";
import DataTable from 'react-data-table-component';
import { jsPDF } from "jspdf";

export const VolDataTable = ({data}) => {
    
    const exportBtn = async () => {
        const pdf = new jsPDF("landscape", "pt", "letter", true);
        const aux = data.map(elt=>[elt.name, elt.email, elt.phone, elt.city, elt.state, elt.country])
        const headers =[["NOMBRE", "EMAIL", "TELEFONO", "CIUDAD", "ESTADO", "PAIS"]]
        const marginLeft = 40;
        const title = "Reporte de Voluntarios";
        let content = {
            startY: 50,
            head: headers,
            body: aux
          };

        pdf.text(title, marginLeft, 40);
        pdf.autoTable(content);
        pdf.save("Vols.pdf")
    }

    const columns = [
        {
            name:'Nombre',
            selector: row=> row.name,
            width:'300px',   
        },
        {
            name:'Email',
            selector: row=> row.email,
            width:'300px',   
        },
        {
            name:'Telefono',
            selector: row=> row.phone
        },
        {
            name:'Ciudad',
            selector: row=> row.city
        },
        {
            name:'Estado',
            selector: row=> row.state
        },
        {
            name:'Pais',
            selector: row=> row.country
        },
    ]

    return(
      <div style={{width:'70%', height: '100%'}}>   
        <div style={{width:'90%'}} id='pdf'>
            <DataTable columns={columns} data={data} customStyles={customStyles} title='VOLUNTARIOS' pagination/>
        </div>
        <button style={{width:'80px', border:'none', color:'#8A87D8 ', fontSize:'18px'}} onClick={exportBtn}>Exportar</button>
        </div>
    )
}

const customStyles = {
    rows: {
       style: {
           color: 'black',
           '&:not(:last-of-type)': {
               borderBottomStyle: 'solid',
               borderBottomWidth: '1px',
               borderBottomColor: 'grey',
              
           },
           
       },
   },
   headRow: {
       style: {
           backgroundColor: '#C5C3F3',
           minHeight: '52px',
           borderBottomWidth: '1px',
           borderBottomColor: 'black',
           borderBottomStyle: 'solid',
       },
   }
};