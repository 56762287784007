import { server } from "../server";

export const getAsocsByState = async() => {
    try {
        const url = `${server}getEstados.php`;
        const resp = await fetch(url, {method: 'GET'});
        const dataAs = await resp.json();
        return dataAs;    
    } catch (errorA) {
        console.log(errorA);
        return {
            errorA: true,
            messageAs: "Ocurrio un error, intente de nuevo",
            dataAs:[]
        };
    }
}
