import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(... registerables)

export const AsocsBarChart = ({estados, count}) => {

    const data ={
        labels: estados,
        datasets: [{
            label: 'Asociaciones',
            data: count,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgb(54, 162, 235)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255, 99, 132, 0.2)',
            hoverBorderColor: 'rgb(255, 99, 132)',
            
        }]
    }

    const opciones = {
        maintainAspectRatio: false,
        responsive: true,
        indexAxis: 'y', 
        title: {
            display:true,
            text: 'Asociaciones por Estado'
        }
    }
    return (
        <div style={{width:'50%', height: '100vh'}}>
            <h1 style={{textAlign: 'center'}}>Asociaciones por ciudad</h1>
         <Bar data={data} options={opciones} type= 'bar'/>
        </div>
    )
}