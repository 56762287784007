export const categories = [
    {
        "id": "1",
        "name": "Bienestar"
    },
    {
        "id": "2",
        "name": "Salud"
    },
    {
        "id": "3",
        "name": "Personas Mayores"
    },
    {
        "id": "4",
        "name": "Medioambientales"
    },
    {
        "id": "5",
        "name": "Culturales"
    },
    {
        "id": "6",
        "name": "Deportivas "
    },
    {
        "id": "7",
        "name": "Animalistas"
    }
]