import { server } from "../server";

export const getAsocsData = async() => {
    try {
        const url = `${server}getAsociationsData.php`;
        const resp = await fetch(url, {
            method: "GET",
            headers: {
              "Access-Token": sessionStorage.getItem("SESSID"),
            },
          });
        const dataAs = await resp.json();
        console.log('data del get data asocs', dataAs);
        return dataAs;    
    } catch (errorA) {
        console.log('este es el error',errorA);
        return {
            errorA: true,
            messageAs: "Ocurrio un error, intente de nuevo",
            dataAs:[]
        };
    }
}