import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(... registerables)

export const CategoryBarChart = ({category, count}) => {

    const data ={
        labels: category,
        datasets: [{
            label: 'Asociaciones',
            data: count,
            backgroundColor: 'rgba(255, 205, 86, .2)',
            borderColor: 'rgba(255, 205, 86, 1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(153, 102, 255, 0.2)',
            hoverBorderColor: 'rgb(153, 102, 255)',
            
        }]
    }

    const opciones = {
        maintainAspectRatio: false,
        responsive: true,
        indexAxis: 'y', 
        title: {
            display:true,
            text: 'Asociaciones por Estado'
        }
    }
    return (
        <div style={{width:'50%', height: '450px'}}>
          <h1 style={{textAlign: 'center'}}>Asociaciones por categoría</h1>
         <Bar data={data} options={opciones} type= 'bar'/>
        </div>
    )
}