import React, { useEffect, useState } from "react";
import { getAsocs } from "../../helpers/getAsocs";
import { getAsocsByState } from "../../helpers/getAsocsByState";
import { Search } from "../others/Search";
import { AsocsList } from "./AsocsScreen/AsocsList";
import { categories } from "./AsocsScreen/categories";
import { AsocsBarChart } from "./AsocsScreen/AsocsBarChart";
import { getAsocsByCategory } from "../../helpers/getAsocsByCategory";
import { CategoryBarChart } from "./AsocsScreen/CategoryBarChart";
import { getAsocsData } from "../../helpers/getAsocsData";
import { AsocsDataTable } from "./AsocsScreen/AsocsDataTable";

export const AsocsScreen = () => {
  const [asocs, setAsocs] = useState([]);
  const [states, setSates] = useState([]);
  const [count, setCount] = useState([]);
  const [category, setCategory] = useState([]);
  const [countCat, setCountCat] = useState([]);
  const [chart, setChart] = useState('ciudad');
  const [asocData, setAsocData] = useState([]);
  const [search, setSearch] = useState({
    search: "",
    category: "i",
  });
  const [isLoading, setIsLoading] = useState(true);

  const handleSearchChange = ({ target }) => {
    setSearch({
      ...search,
      [target.name]: target.value,
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    (async () => {
      const { error, message, data } = await getAsocs(search);
      if (!error) {
        setAsocs(data);
      } else {
        alert(message);
      }
    })();
  };

  const handleChart = ({target}) => {
    var aux = target.value
    setChart(aux)
    console.log(chart);

  }

  useEffect(() => {
    (async () => {
      const { error, data } = await getAsocs({});
      if (!error) {
        setAsocs(data); 
      }
      setIsLoading(false);
    })();
    return () => {
      setAsocs([]);
    };
  }, []); 

 useEffect ( () => {
    fetchData();
    fetchCategoryData();
    fetchAsocsData();
  }, [])

  const fetchData = async () => {
    const dataA = await getAsocsByState();
    if(dataA != undefined){
      var auxStates = dataA.map (function(data){
        return data.estado
       })
       var auxData = dataA.map (function(data){
        return data.count
       })
    }
     setSates(auxStates);
     setCount(auxData);
  }

  const fetchCategoryData = async () => {
    const dataA = await getAsocsByCategory();
    if(dataA != undefined){
      const catArray = dataA.data;
      var auxCat = catArray.map (function(data){
        return data.category
       })
       var auxData = catArray.map (function(data){
        return data.count
       }) 
    } else { console.log('vacio');}

    setCategory(auxCat);
    setCountCat(auxData) 
  }

  const fetchAsocsData = async () => {
    const dataA = await getAsocsData();
    if(dataA != undefined){
      const catArray = dataA.data;
      console.log('data para la tabla', catArray);
      setAsocData(catArray);
    } else { console.log('vacio');}

  }

 if (isLoading) {
    return (
      <div className="loading-container">
        <p>Cargando...</p>
      </div>
    );
  } else {
    return (
      <div className="users-screen__body">
      <div className="users-screen__menu-container"></div>
        <select className= "select-menu" name= "category" value={search.category} onChange= {handleSearchChange}>
          <option value = "i">Categoria</option>
          {categories.map((item)=>(<option key = {item.id} value={item.id}>{item.name}</option>))}
        </select>
        <Search
          className="search_container mt-s "
          onChangeSearch={handleSearchChange}
          Search={search.search}
          onSearch={handleSearch}
        />
        <AsocsList className="users-screen__users-list mt-b" asocs={asocs} />
        <div style={{marginTop:'10px'}}> 
          <select className= "charts_select"  name= "charts" onChange={handleChart}>
            <option key= "0" value = 'elegir'>Elegir gráfica</option>
            <option key= "1" value = 'ciudad'>Asociaciones por ciudad</option>
            <option key= '2' value = 'categoria'>Asociaciones por categoria</option>
            <option key= '3' value = 'tabla'>Tabla de Asociaciones</option>
         </select> 
        </div>
       <div style={{marginLeft:'20px', width:'100%' }}>
            {(chart=='ciudad' && <AsocsBarChart estados={states} count={count}/>)}
            {(chart == 'categoria')&& <CategoryBarChart category={category} count={countCat} />}
            {(chart == 'tabla')&& <AsocsDataTable data = {asocData}/>}
        </div> 
      </div>
      
    );
  }
};
